import { DEFAULT_TEXT_SMALL, LARGE_TEXT_SMALL } from '@/constants/styles';
import { createAddressString } from '@/helpers/common';
import { translate } from '@/localization';
import { useCompany } from '@/modules/company';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ReportOptions } from './interfaces';

const getImageDimensions = async (
  imageData: string
): Promise<{ w: number; h: number }> => {
  return new Promise(function(resolved) {
    const i = new Image();
    i.onload = function() {
      resolved({ w: i.naturalWidth, h: i.naturalHeight });
    };
    i.onerror = function() {
      resolved({ w: 0, h: 0 });
    };
    i.src = imageData;
  });
};

export const createHeader = async (doc: jsPDF, options: ReportOptions) => {
  const MAX_IMAGE_WIDTH = 40; // Maximum width for the logo
  const MAX_IMAGE_HEIGHT = 20; // Maximum height for the logo

  const { getLogo, getCompany } = useCompany();
  const logoData = await getLogo();
  const company = await getCompany();

  const companyName = company?.CompanyName ?? `${translate('common.unknown')}`;

  doc.text(companyName, 14, 18);

  const data = [
    [translate('labels.org-number'), company?.OrganizationNumber ?? ''],
    [translate('labels.address'), createAddressString(company)],
    [translate('labels.email'), company?.Email ?? '-'],
    [translate('labels.phone'), company?.Phone ?? '-'],
  ];

  if (logoData) {
    const logo = `data:image/png;base64,${logoData}`;
    const dims = await getImageDimensions(logo);

    if (dims.w && dims.h) {
      const ratio = Math.min(
        MAX_IMAGE_WIDTH / dims.w,
        MAX_IMAGE_HEIGHT / dims.h
      );
      const imageWidth = dims.w * ratio;
      const imageHeight = dims.h * ratio;

      doc.addImage(logo, 'PNG', 155, 18, imageWidth, imageHeight);
    }
  }

  autoTable(doc, {
    startY: 24,
    body: data,
    theme: 'plain',
    margin: { right: 107 },
    styles: {
      fontSize:
        options.FontSize === 'default' ? DEFAULT_TEXT_SMALL : LARGE_TEXT_SMALL,
      cellPadding: 0.5,
    },
    columnStyles: {
      0: {
        fontStyle: 'bold',
      },
    },
  });
};

export const createHeaderXlsx = async (xlData: (string | number)[][]) => {
  const { getCompany } = useCompany();
  const company = await getCompany();

  const companyName = company?.CompanyName ?? `${translate('common.unknown')}`;

  xlData.push([companyName]);
  xlData.push(['']);
  xlData.push([
    `${translate('labels.org-number')}`,
    company?.OrganizationNumber ?? '',
  ]);
  xlData.push([
    `${translate('labels.address')}`,
    createAddressString(company) ?? '',
  ]);
  xlData.push([`${translate('labels.email')}`, company?.Email ?? '-']);
  xlData.push([`${translate('labels.phone')}`, company?.Phone ?? '-']);
  xlData.push(['']);
};
